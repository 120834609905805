import { AnyObject } from "react-joyride";
import { AuthData } from "sutro-common/auth-data";
import { StudioUserProfile } from "sutro-common/sutro-data-store-types";

/**
 * Interacting with local storage gets a little tricky when you're server-side rendering
 * so we have a few helper functions to make it easier
 */

const retrieve = <O extends AnyObject>(key: string): O | null => {
  if (typeof window === "undefined") {
    console.warn(
      "Sutro: Tried to retrieve from local storage on the server. This is may be a mistake, but it's not always a problem"
    );
    return null;
  }
  const storedInfo = window.localStorage.getItem(key);
  if (storedInfo === null) {
    return null;
  }
  try {
    return JSON.parse(storedInfo) as O;
  } catch (e) {
    console.warn(`There was a problem getting ${key}`);
    window.localStorage.removeItem(key);
    return null;
  }
};

const store = <O extends AnyObject>(o: O, key: string): void => {
  if (typeof window === "undefined") {
    console.warn(
      "Sutro: Tried to store to local storage on the server. This is usually a mistake"
    );
    return;
  }
  const json = JSON.stringify(o);
  window.localStorage.setItem(key, json);
};

const clear = (key: string) => window.localStorage.removeItem(key);

/*
 * The following functions wrap the local storage access to put all the
 * storage keys in one place
 *
 */
export const retrieveStudioUser = () =>
  retrieve<StudioUserProfile>("sutro:studio-user");
export const storeStudioUser = (studioUser: StudioUserProfile) =>
  store(studioUser, "sutro:studio-user");
export const clearStudioUser = () => clear("sutro:studio-user");

export const retrieveAuthData = () => retrieve<AuthData>("sutro:authData");
export const storeAuthData = (authData: AuthData) =>
  store(authData, "sutro:authData");
export const clearAuthData = () => clear("sutro:authData");

export const retrieveHasUserVisitedStudio = () =>
  retrieve<{ hasVisitedStudio: boolean }>("sutro:user-info")
    ?.hasVisitedStudio ?? false;
export const storeHasUserVisitedStudio = (hasVisitedStudio: boolean) =>
  store({ hasVisitedStudio }, "sutro:user-info");
export const clearHasUserVisitedStudio = () => clear("sutro:user-info");
